/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

//mdb css
import "./mdb5-advanced-standard/css/mdb.min.css";
import "./mdb5-advanced-standard/plugins/css/all.min.css";

const routes = require("../public/js/fos_js_routes.json");
import Routing from "../public/bundles/fosjsrouting/js/router.min.js";
Routing.setRoutingData(routes);

// Setting Routing as global there
global.Routing = Routing;

// any CSS you import will output into a single css file (app.css in this case)
import "./styles/app.css";

// start the Stimulus application
import "./bootstrap";

